const OperatorMapping = {
  '<': 'lt',
  '<=': 'lte',
  '>': 'gt',
  '>=': 'gte',
  '=': 'eq',
  '!=': 'ne',
  // String Operators
  'contains': 'iLike',
  'notContain': 'notILike',
  'startsWith': 'startsWith',
  'endsWith': 'endsWith',
  'isEmpty': 'eq',
  'notEmpty': 'ne',
  'equals': 'eq',
  'isAnyOf': 'in',
  };
  
export const getFormattedFilters = (filters) => {
    return Object.entries(filters).reduce((acc, [key, value]) => {
        if (value.length > 0) {
            acc[key] = value.join(',');
        }
        return acc;
    }, {});
};

export const createFilterRequestData = (data) => {
    const { items, logicOperator } = data;
      
    return items
      .filter((i) => (i.hasOwnProperty('value') && i.value) || i.operator === 'isEmpty' || i.operator === 'notEmpty')
      .map((i) => ({
        column: i.field,
        operator: OperatorMapping[i.operator], 
        value: i.value,
        logic: items.length == 1 ? 'AND' : logicOperator.toUpperCase()
      }));
  };