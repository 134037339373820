import { useMutation, useQuery } from "@tanstack/react-query";
import { preferenceApi } from "../../api/endpoints/preferencesApi";


export const useGetPreferences = (options = {}) => {
    const  isLoggedIn  = localStorage.getItem('authStatus');

    return useQuery({
        queryKey: ["preferences", { queryType: options.queryType, key: options.key }],
        queryFn: () => preferenceApi.getPreferences(options),
        enabled: !!isLoggedIn,
        ...options,
    });
};

export const useUpdatePreference = (options = {}) => {
    return useMutation({
        mutationFn: (data) => {
            preferenceApi.updatePreferences(data);
        },
        ...options,
    });
};
