import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { IconButton, Menu, MenuItem } from '@mui/material';
import ProfileImage from '../../asset/image/profile.png';

const UserMenu = ({className=''}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate(); 

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    localStorage.removeItem('authStatus');
    localStorage.removeItem('authToken');
    localStorage.removeItem('2faVerified');
    navigate('/');
  };

  return (
    <div className={`user_dropdown ${className}`}>
      <IconButton onClick={handleClick}>
        <img src={ProfileImage} alt="Profile" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => navigate("/profile")}>Profile</MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleLogout();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
