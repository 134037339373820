import React from 'react'
import { Button } from '@mui/material'

const GradientButton = ({ onClick, startIcon, children, disabled = false }) => {
    return (
        <Button
            onClick={onClick}
            variant="contained"
            startIcon={startIcon}
            disabled={disabled}
            sx={{
                background: 'linear-gradient(62.72deg, #903AD9 6.2%, #4170E5 46.41%, #11BCC6 76.75%)',
                color: 'white',
                '&:hover': {
                    background: 'linear-gradient(62.72deg, #903AD9 6.2%, #4170E5 46.41%, #11BCC6 76.75%)',
                    opacity: 0.9,
                },
                fontSize: 14,
                fontWeight: '700 !important',
                borderRadius: 3,
                textTransform: 'none',
                whiteSpace: 'nowrap',
                padding: '10px 16px',
      
            }}
        >
            {children}
        </Button>)
}

export default GradientButton


