import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PrivateLayout from "../components/layout/PrivateLayout";
import FullscreenLayout from "../components/layout/FullScreenLayout.jsx";
import "../App.css";

const PrivateRoute = ({ children }) => {
  const authToken = localStorage.getItem("authToken");
  const twoFAVerified = localStorage.getItem("2faVerified");
  const location = useLocation();
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (!authToken || !twoFAVerified) {
      // Save the intended URL for redirection after login and 2FA
      localStorage.setItem('redirectUrl', location.pathname + location.search);
    }
  }, [authToken, twoFAVerified, location]);

    // Redirect to "/" if authToken or 2FA verification is missing
    if (!authToken || !twoFAVerified || twoFAVerified === "false") {
      return <Navigate to="/" state={{ from: location }} replace />;
    }

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { onToggleFullscreen: toggleFullscreen, isFullscreen })
  );

  if (isFullscreen) {
    return <FullscreenLayout>{childrenWithProps}</FullscreenLayout>;
  }

  return <PrivateLayout>{childrenWithProps}</PrivateLayout>;
};

export default PrivateRoute;